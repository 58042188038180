<template>
  <div>
    <v-row v-if="ready">
      <v-col cols="12">
        <h1>Welcome to MixCare Wellness Admin Portal</h1>
      </v-col>

      <v-col
        v-for="(itemOption, index) in sortedOptions"
        :key="`${itemOption.nicename}_${index}`"
        :cols="itemOption.cols"
      >
        <DashboardItem
          :options="itemOption"
          @submit="form => handleUpdates(form, index)"
          @remove="itemsOptions.splice(index, 1)"
        >
        </DashboardItem>
      </v-col>

      <v-col cols="12">
        <a @click="addChart">Add Chart +</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { computed, ref } from '@vue/composition-api'
import DashboardItem from './chart-resource/DashboardItem.vue'

const defaultCharts = [
  {
    cols: 6,
    nicename: 'No. Orders',
    sort: 2,
    route: 'orders',
  },
  {
    cols: 6,
    nicename: 'Refund Overview',
    sort: 3,
    formatter: 'dollar',
    query: {
      action: 'sum',
      action_value: 'refund_total',
    },
  },
  {
    cols: 6,
    nicename: 'Sales Amount',
    sort: 4,
    formatter: 'dollar',
  },
  {
    cols: 12,
    nicename: 'Sales Overview',
    sort: 1,
    formatter: 'dollar',
    query: {
      action: 'sum',
      action_value: 'total',
    },
  },
]

export default {
  components: {
    DashboardItem,
  },

  setup() {
    const ready = ref(true)

    const loadCharts = () => {
      const charts = localStorage.getItem('crm-chart')

      return charts ? JSON.parse(charts) : defaultCharts
    }

    const itemsOptions = ref(loadCharts())

    const sortedOptions = computed(() => itemsOptions.value.sort((a, b) => a.sort - b.sort))

    const saveChart = () => {
      localStorage.setItem('crm-chart', JSON.stringify(sortedOptions.value))
    }

    const handleUpdates = (form, index) => {
      console.log('form is', form)
      itemsOptions.value[index] = form
      ready.value = false
      setTimeout(() => { ready.value = true }, 10)
      saveChart()
    }

    const addChart = () => {
      itemsOptions.value.push({
        cols: 6,
        nicename: 'New Chart',
      })
      saveChart()
    }

    return {
      ready,
      itemsOptions,
      sortedOptions,
      handleUpdates,
      addChart,
    }
  },
}
</script>
