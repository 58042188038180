import handleAggregation from '@/api/aggregation'

export default function useAggregation() {
  // const aggregateOrderItems = () => [1, 2, 3, 4, 5, 6, 7]
  // const handle = () => [1, 2, 3, 4, 5, 6, 7]
  const handle = ({ route, query, filter }) => handleAggregation(route, { ...query, ...filter })

  return {
    handle,

    // aggregateOrderItems,
  }
}
